import { render, staticRenderFns } from "./numberrulelist-WTD.vue?vue&type=template&id=a698d3ec&scoped=true&"
import script from "./numberrulelist-WTD.vue?vue&type=script&lang=js&"
export * from "./numberrulelist-WTD.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.10.0_cutmzj7votxcrccm4agyl3addi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a698d3ec",
  null
  
)

export default component.exports