<script>
import moment from "moment";

/***
 Name: create
 Code: sa0ChunLuyu
 Time: 2021/11/22 21:42
 Remark: 编辑任务 创建
 */
import NumberrulelistWTD from './numberrulelist-WTD/numberrulelist-WTD.vue';//w选择其它编号规则
import chooseTpl from './choose_tpl.vue'

export default {
  components: {chooseTpl, NumberrulelistWTD},
  data() {
    return {
      level: app_config.level,
      copy_data: {
        id: 0,
        clear: 0
      },
      edit: 1,
      task_info: false,
      copy_info: false,
      task_content: [],
      copy_content: [],
      fuzeren_list: [],
      jiaoyanren_list: [],
      bianzhi_list: [],
      shenhe_list: [],
      fz_list: [],
      list_search: {
        page: 1,
        type: 0,
        search: ''
      },
      Numberrulelist: false,
      fuzeren_list1: '',
      yangpinguize_list: [],
      dataSource: {
        com_name: []
      },
      dataSource1: ['Burns Bay Road', 'Downing Street', 'Wall Street'],

    }
  },
  watch: {
    $route: {
      handler() {
        // 深度监听 路由变化
        this.mountedDo()
      },
      deep: true
    },
  },
  mounted() {
    this.mountedDo()
  },
  methods: {
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
      );
    },
    mountedDo() {
      // 开始读取 URL 上面的参数 page - 页数 type - 类型 search - 搜索 edit - 编辑类型 1-基础信息 2-样品信息
      this.list_search.page = this.$route.query.page ? Number(this.$route.query.page) : 1;
      this.list_search.type = this.$route.query.type ? Number(this.$route.query.type) : 0;
      this.list_search.search = this.$route.query.search ? this.$route.query.search : '';
      this.edit = this.$route.query.edit ? Number(this.$route.query.edit) : 1;
      // 读取完成之后 开始执行 获取 任务委托单列表的函数
      this.getTaskInfo()
      this.getFzList()
      this.getbianzhiList()
      this.getJiaoyanrenList()
      this.getShenheList()
      this.yangpinguizeliebiao()

    },

    onSearch1(searchText) {
      this.$sa0.post({
        url: this.$api('AutoGet_task'),
        data: {
          search_value: searchText,
          ziduan_param: 'com_name'
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            console.log(999999, response.data.list)
            if (response.data.list.length > 0) {
              this.dataSource.com_name = response.data.list.filter(item => {
                return item.param_name
              }).map(i => i.param_name)
            } else {
              this.dataSource.com_name = []
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },


    yangpinguizeliebiao() {
      this.$sa0.post({
        url: this.$api('样品管理：获取编号列表'),
        data: {
          num_type: 0
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.yangpinguize_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getyangpinbianhao(id) {
      this.$sa0.post({
        url: this.$api('样品管理：获取样品编号'),
        data: {
          id, num: 1
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.task_info.task_num = response.data.serialnumber[0]
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getyangpinbianhaomoren() {
      // 样品管理：获取样品编号
      this.$sa0.post({
        url: this.$api('样品管理：获取样品默认编号'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.getyangpinbianhao(response.data.info.id)
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },


    getFzList() {
      this.$sa0.post({
        url: this.$api('负责人列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.fz_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    checkCopy() {
      let id = this.$sa0.localStorage.get('COPYTASK')
      id = id ? id : 0
      if (id) {
        this.$sa0.post({
          url: this.$api('委托单详情'),
          data: {
            id,
          }
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              // 对任务详情赋值
              this.copy_info = response.data.info
              this.task_info.name = this.copy_info.name
              this.task_info.com_name = this.copy_info.com_name
              this.task_info.com_per = this.copy_info.com_per
              this.task_info.com_phone = this.copy_info.com_phone
              this.task_info.input_date = this.copy_info.input_date
              this.task_info.is_fast = this.copy_info.is_fast
              this.task_info.task_type = this.copy_info.task_type
              this.task_info.remark = this.copy_info.remark
              this.task_info.check_id = this.copy_info.check_id
              this.task_info.check_ids = this.copy_info.check_ids
              this.task_info.jiaoyan_id = this.copy_info.jiaoyan_id
              this.task_info.bianzhi_id = this.copy_info.bianzhi_id
              this.copy_data.id = this.copy_info.id
              this.copy_content = response.data.content.map((item) => {
                let con = item
                let content = con.table_content
                let value = ''
                // 1567 直等  234 解JSON
                switch (con.type) {
                  case 0:
                  case 1:
                  case 5:
                  case 6:
                  case 7:
                    content = con.table_content;
                    break;
                  case 2:
                  case 3:
                  case 4:
                    content = JSON.parse(con.table_content);
                    break;
                }
                // 12457 直等  36 解JSON
                switch (con.type) {
                  case 0:
                  case 1:
                  case 2:
                  case 4:
                  case 5:
                  case 7:
                    value = con.taskcontent
                    break;
                  case 3:
                  case 6:
                    value = JSON.parse(con.taskcontent)
                    break;
                }
                con.value = value;
                con.content = content;
                return con
              })

              let this_ = this
              layer.confirm('是否替换模板？', {
                btn: ['确定', '取消'] //按钮
              }, function () {
                this_.task_content = [];
                setTimeout(() => {
                  this_.task_content = this_.copy_content
                })

                layer.msg('替换成功', {icon: 1});
              }, function () {
              });
            },
            error: (response) => {
              layer.msg(response.message)
            },
          })
        })
      }
    },
    routerPush(page, type, search, id, edit) {
      // router 推送
      // page 页数
      // type 获取类型
      // search 搜索名称或者编号
      // id 编辑的 任务ID
      // edit 编辑类型  1-基础信息 2-样品信息
      this.$router.push({
        params: {
          id
        },
        query: {
          page, type, search, edit
        }
      })
    },
    getTaskInfo() {
      // 获取委托单详情
      this.$sa0.post({
        url: this.$api('委托单详情'),
        data: {
          id: this.$route.params.id,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            // 对任务详情赋值
            this.task_info = response.data.info
            if (this.level === 2 && this.task_info.check_id === 0 && this.$store.state.admin_info.info.id === 360) {
              this.task_info.check_id = 360
            }

            // 开始解析 任务表单
            this.task_content = response.data.content.map((item) => {
              let con = item
              let content = con.table_content
              let value = ''
              // 1567 直等  234 解JSON
              switch (con.type) {
                case 0:
                case 1:
                case 5:
                case 6:
                case 7:
                  content = con.table_content;
                  break;
                case 2:
                case 3:
                case 4:
                  content = JSON.parse(con.table_content);
                  break;
              }
              // 12457 直等  36 解JSON
              switch (con.type) {
                case 0:
                case 1:
                case 2:
                case 4:
                case 5:
                case 7:
                  value = con.taskcontent
                  break;
                case 3:
                case 6:
                  value = JSON.parse(con.taskcontent)
                  break;
              }
              con.value = value;
              con.content = content;
              return con
            })
            this.checkCopy()
            this.getfuzerenList()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    createTable(edit) {
      // 设置 必填的字段
      let info_not_null = [
        'name',
        'task_num',
        'com_name',
      ];
      let info_title = {
        'name': '委托单名称',
        'task_num': '委托单编号',
        'com_name': '委托单位名称',
      }
      // 循环 基本信息的每一项 看看 必填项 填没填
      let err = ''
      for (let i in this.task_info) {
        // 先看字段 需不需要 必填
        if (info_not_null.indexOf(i) !== -1) {
          // 如果是空的  就给 err 赋值
          if (this.task_info[i] === '') err = info_title[i]
        }
      }
      // 判断 err 不为空 就是 有必填项 没填
      if (err !== '') return layer.msg(err + '不能为空')
      // 开始判断 自定义信息有没有必填项没填
      let content = this.task_content
      // 能到这里 说明 err = ''   所以没必要 重置  但是为了 代码清晰一点  开始写了。  自己写的时候  下面的一行 可以不要
      err = ''
      // 循环每一项 看看 必填的 有没有空的
      content.map((item) => {
        if (Number(item.not_null) === 1) {
          switch (item.type) {
            case 0:
            case 1:
            case 2:
            case 4:
            case 5:
            case 7:
              if (item.value === '') err = item.title;
              break;
            case 3:
              if (item.value.length === 0) err = item.title;
              break;
            case 6:
              item.value.map((i) => {
                if (i === '') err = item.title;
              })
              break;
          }
        }
      })
      if (err !== '') return layer.msg(err + '不能为空')
      let data = {
        ...this.task_info, // 把 task_info 里面的每一项 平摊赋值给 data
        copy_data: this.copy_data,
        table_arr: content.map((item) => {
          // 格式化 表单内容
          let value = item.value
          switch (item.type) {
            case 3:
            case 6:
              value = JSON.stringify(item.value)
              break;
          }
          return {
            form_word_id: item.form_word_id,
            taskcontent: value,
            not_null: item.not_null,
          }
        })
      }
      data.check_ids = JSON.stringify([data.check_id]);
      this.$sa0.post({
        url: this.$api('创建委托单'),
        data
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg('创建成功')
            this.$sa0.localStorage.del('COPYTASK')
            if (edit === 1) {
              this.routerPush(
                  this.list_search.page,  // 页数
                  this.list_search.type,  // 获取类型
                  this.list_search.search,  // 搜索名称或者编号
                  response.data.id, // 编辑的 任务ID
                  edit
              )
            } else {
              // response.data.id
              this.$sa0.localStorage.set('OPEN_TASK', 1)
              this.$router.push({
                name: 'samplelist',
                params: {
                  id: response.data.id
                },
                query: {}
              })
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getJiaoyanrenList() {
      this.$sa0.post({
        url: this.$api('任务:校验人列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.jiaoyanren_list = response.data.list;
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getShenheList() {
      this.$sa0.post({
        url: this.$api('任务:审核人列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.shenhe_list = response.data.list;
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getbianzhiList() {
      this.$sa0.post({
        url: this.$api('任务:编制人列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.bianzhi_list = response.data.list;
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getfuzerenList() {
      this.$sa0.post({
        url: this.$api('委托单：负责人列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.fuzeren_list = []
            if (this.level === 2 && this.$store.state.admin_info.info.id === 360) {
              response.data.list.map((item) => {
                if (item.id === 360) {
                  this.fuzeren_list.push(item)
                }
              })
            } else {
              this.fuzeren_list = response.data.list;
            }


            this.fuzeren_list1 = response.data;

            // if (Number(this.task_info.check_id) == 0) {
            //   this.task_info.check_id = response.data.admin_id
            // }
            console.log(987456, this.fuzeren_list1.admin_id)
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    imageUpload(e, key, k) {
      // 这个是 上传图片 的函数
      let param = new FormData()
      param.append('file', e.target.files[0])
      this.$sa0.file({
        url: this.$api('上传图片'),
        data: param
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          error: () => {
            layer.msg('上传失败')
          },
          then: (res) => {
            layer.msg('上传成功')
            this.$set(this.task_content[key].value, k, res.data.url)
          }
        })
      })
    },
    changeTpl() {
      let ti = JSON.parse(JSON.stringify(this.$refs.choose_tpl.t_info))
      let tc = JSON.parse(JSON.stringify(this.$refs.choose_tpl.t_content))
      this.task_info = ti
      this.task_content = tc
      this.$refs.choose_tpl.visible = false
    },
    chooseTpl() {
      // 让子组件 choose_tpl 获取列表
      this.$refs.choose_tpl.getTplList()
    },
    //默认委托单编号
    nummoren() {
      this.$sa0.post({
        url: this.$api('Get_default_serialnumber'),
        data: {
          num_type: 0,
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.task_info.task_num = response.data.serialnumber;
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    pushOrder(order) {
      this.task_info.task_num = order
      this.modalClose01();
    },
    // 定义 打开对话框时的 函数 //W编号规则弹窗
    showModal01() {
      this.Numberrulelist = true
    },
    // 定义 关闭对话框时的 函数 //W编号规则弹窗
    modalClose01() {
      this.Numberrulelist = false
    },
  }
}
</script>
<template>
  <div class="mt-10">
    <chooseTpl :changeTpl="changeTpl" :task_content="task_content" :task_info="task_info" ref="choose_tpl"></chooseTpl>
    <a-space align="start">
      <div class="table_wrapper ml-5">
        <table class="layui-table">
          <colgroup>
            <col width="200">
            <col width="250">
            <col width="150">
            <col width="200">
            <col width="100">
            <col width="200">
          </colgroup>
          <tbody>
          <tr>
            <td class="table_title_bg "><span class="text-red-400 vm ">*</span> 委托单名称</td>

            <td colspan="3" class="text-l">
              <a-input v-model="task_info.name" type="text"/>
            </td>

            <td class="table_title_bg">负责人</td>
            <td>
              <a-select v-model="task_info.check_id" class="w-full">
                <!--                                <a-select-option value="" v-if="this.fuzeren_list1.admin_id===''" >总管理员</a-select-option>-->
                <a-select-option :value="0">暂无</a-select-option>
                <a-select-option v-for="(bzi,bzk) in fuzeren_list" :value="bzi.id">{{ bzi.name }}
                </a-select-option>
              </a-select>
            </td>
          </tr>
          <tr>
            <td class="table_title_bg"> 委托单编号</td>
            <td>


              <a-space style="position: relative">
                <a-input placeholder="委托单编号" style="width: 250px" v-model="task_info.task_num"
                         @keyup.enter="nummoren"></a-input>
                <a-dropdown style="position: absolute; right: 15px; top: 15px">
                  <a-icon type="unordered-list" @click="nummoren()" style="cursor:pointer; vertical-align: middle"/>
                  <a-menu slot="overlay">
                    <a-menu-item v-for="(ri,rk) in yangpinguize_list" :key="rk" @click="getyangpinbianhao(ri.id)">
                      {{ ri.name }} {{ ri.serialnumber }}
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </a-space>

            </td>
            <td class="table_title_bg"><span class="text-red-400 vm ">*</span> 委托单位名称</td>
            <td>

              <a-auto-complete v-model="task_info.com_name" :data-source="dataSource.com_name" style="width: 200px"
                               placeholder="请输入委托单位名称" :filter-option="filterOption" @focus="onSearch1"/>


            </td>
            <td class="table_title_bg">委托类型</td>
            <td>
              <a-select v-model="task_info.task_type" class="w-full">
                <a-select-option :value="1">内部</a-select-option>
                <a-select-option :value="2">外部</a-select-option>
              </a-select>
            </td>
          </tr>
          <tr>
            <td class="table_title_bg">报告编制人</td>
            <td>
              <a-select v-model="task_info.bianzhi_id" class="w-full">
                <a-select-option :value="0">暂无</a-select-option>
                <a-select-option v-for="(bzi,bzk) in bianzhi_list" :value="bzi.id">{{ bzi.name }}
                </a-select-option>
              </a-select>
            </td>
            <td class="table_title_bg">报告校验人</td>
            <td>
              <a-select v-model="task_info.jiaoyan_id" class="w-full">
                <a-select-option :value="0">暂无</a-select-option>
                <a-select-option v-for="(jyi,jyk) in jiaoyanren_list" :value="jyi.id">{{ jyi.name }}
                </a-select-option>
              </a-select>
            </td>
            <td class="table_title_bg">报告审核人</td>
            <td>
              <a-select v-model="task_info.shenhe_id" class="w-full">
                <a-select-option :value="0">暂无</a-select-option>
                <a-select-option v-for="(shi,shk) in shenhe_list" :value="shi.id">{{ shi.name }}
                </a-select-option>
              </a-select>
            </td>

          </tr>
          <tr>
            <td class="table_title_bg">联系人</td>
            <td>
              <a-input v-model="task_info.com_per" type="text"/>
            </td>
            <td class="table_title_bg">电话</td>
            <td>
              <a-input v-model="task_info.com_phone" type="text"/>
            </td>
            <td class="table_title_bg">办理时间</td>
            <td>
              <a-date-picker v-model="task_info.input_date" class="w-full" show-time format="YYYY-MM-DD HH:mm:ss"
                             valueFormat="YYYY-MM-DD HH:mm:ss"/>
            </td>
          </tr>
          <tr v-for="(item,key) in task_content" :key="key">
            <td class="table_title_bg" :class="[item.not_null === 1?'text-red-400':'']">{{ item.title }}
            </td>
            <td colspan="5 " class="text-l">
              <div class="h-full" v-if="item.type === 1">
                <a-input class="w-full h-full input_wrapper" :placeholder="item.content" v-model="item.value"></a-input>
              </div>
              <div class="input_select_wrapper" v-if="item.type === 2">
                <a-select class="h-full w-full" :default-value="item.content[0]" v-model="item.value">
                  <a-select-option :value="i" v-for="(i,k) in item.content">
                    {{ i }}
                  </a-select-option>
                </a-select>
              </div>
              <div class="content_item_wrapper input_line_wrapper" v-if="item.type === 3">
                <a-checkbox-group v-model="item.value">
                  <a-checkbox v-for="(i,k) in item.content" :value="i">
                    {{ i }}
                  </a-checkbox>
                </a-checkbox-group>
              </div>
              <div class="content_item_wrapper input_line_wrapper" v-if="item.type === 4">
                <a-radio-group v-model="item.value">
                  <a-radio v-for="(i,k) in item.content" :value="i">
                    {{ i }}
                  </a-radio>
                </a-radio-group>
              </div>
              <div class="content_item_wrapper input_line_wrapper" v-if="item.type === 5">
                <a-textarea v-model="item.value" :placeholder="item.content"></a-textarea>
              </div>
              <div class="content_image_item_wrapper" v-if="item.type === 6">
                <div class="image_item_wrapper" v-for="(i,k) in Number(item.content)">
                  <input type="file" class="image_input_wrapper" @change="(e)=>{imageUpload(e,key,k)}">
                  <div class="image_image_wrapper" v-if="item.value[k] !== ''">
                    <img :src="item.value[k]"/>
                  </div>
                  <a-icon type="plus"/>
                </div>
              </div>
              <div class="content_image_item_wrapper input_line_wrapper" v-if="item.type === 7">
                <a-date-picker v-model="item.value" v-if="Number(item.content) === 0" format="YYYY-MM-DD"
                               valueFormat="YYYY-MM-DD" style="width: 400px"/>
                <a-time-picker v-model="item.value" v-if="Number(item.content) === 1" format="HH:mm:ss"
                               valueFormat="HH:mm:ss" style="width: 400px"/>
                <a-date-picker v-model="item.value" show-time v-if="Number(item.content) === 2"
                               format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss" style="width: 400px"/>
              </div>
            </td>
          </tr>
          <tr>
            <td class="table_title_bg">备注</td>
            <td colspan="3" style="padding: 10px">
              <a-textarea type="text" v-model="task_info.remark"/>
            </td>
            <td class="table_title_bg">任务加急</td>
            <td>
              <a-radio-group v-model="task_info.is_fast" name="radioGroup">
                <a-radio :value="1">
                  是
                </a-radio>
                <a-radio :value="2">
                  否
                </a-radio>
              </a-radio-group>
            </td>
          </tr>
          <tr v-if="copy_info && copy_info.type === 2">
            <td class="table_title_bg">绑定样品</td>
            <td colspan="5">
              <a-radio-group v-model="copy_data.id" name="radioGroup">
                <a-radio :value="copy_info.id">
                  是
                </a-radio>
                <a-radio :value="0">
                  否
                </a-radio>
              </a-radio-group>
            </td>
          </tr>
          </tbody>
        </table>
        <div>
          <a-space>
            <a-button @click="createTable(1)" type="primary">保存</a-button>
            <a-button @click="createTable(2)">保存并开始添加样品</a-button>
            <a-button @click="chooseTpl()" type="link">切换表单模板</a-button>
          </a-space>
        </div>
      </div>
    </a-space>
    <NumberrulelistWTD :callback="pushOrder" :ok="modalClose01" :cancel="modalClose01"
                       :Numberrulelist="Numberrulelist"></NumberrulelistWTD>
  </div>
</template>
<style scoped>
.edit_tab_wrapper {
  width: 200px;
  height: 40px;
}

td {
  line-height: 40px;
  padding: 0 10px;
}

.input_wrapper {
  max-width: 400px;
  height: 100%;
  border: 1px solid #d9d9d9;
  height: 36px;
  line-height: 36px;
  border-radius: 2px;
  padding: 5px;
}

.input_select_wrapper {
  width: 400px;
}

.table_title_bg {
  background: #fafcff;
}

.layui-table td {
  padding: 0 10px;
  height: 40px;
  border-color: #ececec;
}

.content_image_item_wrapper {
  width: 600px;
}

.image_item_wrapper {
  position: relative;
  overflow: hidden;
  margin: 10px 10px 10px 0;
  display: inline-block;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border-radius: 6px;
  border: 1px solid #5a5e6630;
  background: #5a5e6605;
}

.image_image_wrapper img {
  width: 100%;
  height: 100%;
}

.image_image_wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.image_input_wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  cursor: pointer;
}

.input_line_wrapper {
  width: 500px;
  display: inline-block;
}

.layui-table th {
  text-align: left
}

.vm {
  vertical-align: middle;
  margin-right: 5px
}

.text-l {
  text-align: left !important
}
</style>
