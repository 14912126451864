<script>
    /***
     Name: choose_tpl
     Code: sa0ChunLuyu
     Time: 2021/11/23 15:33
     Remark: 选择模板
     */
    export default {
        props: ['task_content', 'task_info', 'changeTpl'],
        data() {
            return {
                t_content: [],
                t_info: false,
                visible: false,
                tpl_list: [],
            }
        },
        mounted() {
        },
        methods: {
            getTplList() {
                this.$sa0.post({
                    url: this.$api('委托单模板')
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            // 获取列表 赋值
                            this.tpl_list = response.data.list
                            // 内容深度拷贝
                            this.t_content = JSON.parse(JSON.stringify(this.task_content))
                            this.t_info = JSON.parse(JSON.stringify(this.task_info))
                            // 打开弹框
                            this.visible = true
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            itemClick(keys, e) {
                // 看看 传进来的 有没有ID
                let id = keys[0] ? keys[0] : 0
                if (id) {
                    // 把点击的 item 拿出来
                    let item = e.node.dataRef
                    // 如果是 模板 不是文件夹 就开始 替换模板
                    if (item.type === 1) {
                        this.$sa0.post({
                            url: this.$api('模板详情'),
                            data: {id},
                        }).then((response) => {
                            this.$sa0.response({
                                response: response.data,
                                then: (response) => {
                                    let ids = this.t_content.map((i) => {
                                        return i.form_word_id
                                    });
                                    this.t_content = response.data.content.map((iitem) => {
                                        let con = iitem
                                        let content = con.table_content
                                        let value = ''
                                        // 1567 直等  234 解JSON
                                        switch (con.type) {
                                            case 0:
                                            case 1:
                                            case 5:
                                            case 6:
                                            case 7:
                                                content = con.table_content;
                                                break;
                                            case 2:
                                            case 3:
                                            case 4:
                                                content = JSON.parse(con.table_content);
                                                break;
                                        }
                                        // 12457 直等  36 解JSON
                                        let ids_key = ids.indexOf(con.form_word_id)
                                        if (ids_key !== -1) {
                                            console.log(this.t_content[ids_key].value)
                                            value = this.t_content[ids_key].value
                                        } else {
                                            switch (con.type) {
                                                case 0:
                                                case 1:
                                                case 2:
                                                case 4:
                                                case 5:
                                                case 7:
                                                    value = con.taskcontent
                                                    break;
                                                case 3:
                                                case 6:
                                                    value = JSON.parse(con.taskcontent)
                                                    break;
                                            }
                                        }

                                        con.value = value;
                                        con.content = content;
                                        return con
                                    })

                                },
                                error: (response) => {
                                    layer.msg(response.message)
                                },
                            })
                        })
                    }
                }

            },
            handleOk() {
                this.changeTpl()
            },
            handleCancel() {
                this.visible = false
            },
        }
    }
</script>
<template>
    <div>
        <a-drawer width="80%" title="选择表单" placement="right" :visible="visible" @close="handleCancel">
            <div>
                <a-space align="start">
                    <div>
                        <a-tree :tree-data="tpl_list" :replaceFields="{children:'children', title:'name', key:'id' }" :show-line="true" @select="itemClick">
                            <a-icon slot="icon" type="carry-out"/>
                        </a-tree>
                    </div>
                    <div>
                        <table class="layui-table">
                            <colgroup>
                                <col width="200">
                                <col width="250">
                                <col width="150">
                                <col width="200">
                                <col width="100">
                                <col width="200">
                            </colgroup>
                            <tbody>
                            <tr>
                                <td class=" table_title_bg"><span class="text-red-400 vm ">*</span> 委托单名称</td>
                                <td colspan="5">
                                    <input v-model="t_info.name" type="text" class="input_wrapper">
                                </td>
                            </tr>
                            <tr>
                                <td class=" table_title_bg"><span class="text-red-400 vm ">*</span> 委托单编号</td>
                                <td>
                                    <input v-model="t_info.task_num" type="text" class="input_wrapper">
                                </td>
                                <td class=" table_title_bg"><span class="text-red-400 vm ">*</span> 委托单位名称</td>
                                <td>
                                    <input v-model="t_info.com_name" type="text" class="input_wrapper">
                                </td>
                                <td class="table_title_bg">委托类型</td>
                                <td>
                                    <a-select v-model="t_info.task_type" class="w-full">
                                        <a-select-option :value="1">内部</a-select-option>
                                        <a-select-option :value="2">外部</a-select-option>
                                    </a-select>
                                </td>
                            </tr>
                            <tr>
                                <td class="table_title_bg">联系人</td>
                                <td><input v-model="t_info.com_per" type="text" class="input_wrapper"></td>
                                <td class="table_title_bg">电话</td>
                                <td><input v-model="t_info.com_phone" type="text" class="input_wrapper">
                                </td>
                                <td class="table_title_bg">办理时间</td>
                                <td>
                                    <a-date-picker v-model="t_info.input_date" class="w-full" show-time format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss"/>
                                </td>
                            </tr>
                            <tr v-for="(item,key) in t_content" :key="key">
                                <td class="table_title_bg" :class="[item.not_null === 1?'text-red-400':'']">{{
                                    item.title }}
                                </td>
                                <td colspan="5" class="text-l">
                                    <div class="h-full" v-if="item.type === 1">
                                        <input class="w-full h-full" :placeholder="item.content" v-model="item.value"></input>
                                    </div>
                                    <div class="input_select_wrapper" v-if="item.type === 2">
                                        <a-select class="h-full w-full" :default-value="item.content[0]" v-model="item.value">
                                            <a-select-option :value="i" v-for="(i,k) in item.content">
                                                {{ i }}
                                            </a-select-option>
                                        </a-select>
                                    </div>
                                    <div class="content_item_wrapper input_line_wrapper" v-if="item.type === 3">
                                        <a-checkbox-group v-model="item.value">
                                            <a-checkbox v-for="(i,k) in item.content" :value="i">
                                                {{ i }}
                                            </a-checkbox>
                                        </a-checkbox-group>
                                    </div>
                                    <div class="content_item_wrapper input_line_wrapper" v-if="item.type === 4">
                                        <a-radio-group v-model="item.value">
                                            <a-radio v-for="(i,k) in item.content" :value="i">
                                                {{ i }}
                                            </a-radio>
                                        </a-radio-group>
                                    </div>
                                    <div class="content_item_wrapper input_line_wrapper" v-if="item.type === 5">
                                        <a-textarea v-model="item.value" :placeholder="item.content"></a-textarea>
                                    </div>
                                    <div class="content_image_item_wrapper" v-if="item.type === 6">
                                        <div class="image_item_wrapper" v-for="(i,k) in Number(item.content)">
                                            <input type="file" class="image_input_wrapper" @change="(e)=>{imageUpload(e,key,k)}">
                                            <div class="image_image_wrapper" v-if="item.value[k] !== ''">
                                                <img :src="item.value[k]"/>
                                            </div>
                                            <a-icon type="plus"/>
                                        </div>
                                    </div>
                                    <div class="content_image_item_wrapper input_line_wrapper" v-if="item.type === 7">
                                        <a-date-picker v-model="item.value" v-if="Number(item.content) === 0" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" style="width: 400px"/>
                                        <a-time-picker v-model="item.value" v-if="Number(item.content) === 1" format="HH:mm:ss" valueFormat="HH:mm:ss" style="width: 400px"/>
                                        <a-date-picker v-model="item.value" show-time v-if="Number(item.content) === 2" format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss" style="width: 400px"/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="table_title_bg">备注</td>
                                <td colspan="3" style="padding: 10px">
                                    <a-textarea v-model="t_info.remark" class="input_wrapper"/>
                                </td>
                                <td class="table_title_bg">任务加急</td>
                                <td>
                                    <a-radio-group v-model="t_info.is_fast" name="radioGroup">
                                        <a-radio :value="1">
                                            是
                                        </a-radio>
                                        <a-radio :value="2">
                                            否
                                        </a-radio>
                                    </a-radio-group>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <a-button @click="handleOk()" type="primary">替换模板</a-button>
                    </div>
                </a-space>
            </div>
        </a-drawer>
    </div>
</template>
<style scoped>
    .edit_tab_wrapper{
        width: 200px;
        height: 40px;
        }

    td{
        line-height: 40px;
        padding: 0 10px;
        }

    .input_wrapper{
        width: 100%;
        height: 100%;
        }
    .input_select_wrapper{
        width: 400px;
        }

    .table_title_bg{
        background: #fafcff;
        }

    .layui-table td{
        padding: 0 10px;
        height: 40px;
        border-color: #ececec;
        }

    .content_image_item_wrapper{
        width: 600px;
        }

    .image_item_wrapper{
        position: relative;
        overflow: hidden;
        margin: 10px 10px 10px 0;
        display: inline-block;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
        border-radius: 6px;
        border: 1px solid #5a5e6630;
        background: #5a5e6605;
        }

    .image_image_wrapper img{
        width: 100%;
        height: 100%;
        }

    .image_image_wrapper{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        }

    .image_input_wrapper{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0;
        cursor: pointer;
        }

    .input_line_wrapper{
        width: 500px;
        display: inline-block;
        }

    .text-l{ text-align: left}

    .vm{ vertical-align: middle;}

    .ant-calendar-picker-input{ width: 400px !important}
</style>
