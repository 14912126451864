<script>
/***
 Name: add_sample
 Code: sa0ChunLuyu
 Time: 2021/11/24 09:41
 Remark: 添加样品
 */
import Numberrulelistuser from '../numberrulelistuser/index.vue';

export default {
  props: ['listReload'],
  components: {
    Numberrulelistuser
  },
  data() {
    return {
      num_input_show: false,
      modal_visible: false,
      samples: [],
      create_info: {
        spec_type: '',
        spec_types: [],
        name: '',
        in_number: '',
        pick_per: '',
        pick_pers: [],
        jing_dot: '',
        wei_dot: '',
        pick_space: '',
        other_pick_per: [],
        other_pick_pers: [],
      },
      other_pick_per: '',
      other_pick_pers: [],
      get_number: "",
      pickper_list: [],
      types_list: [],
      visible: false,
      number_config: {
        id: 0,
        num: 1
      }
    }
  },
  mounted() {
  },
  methods: {
    delOtherPicker(key) {
      let picker_list = JSON.parse(JSON.stringify(this.create_info.other_pick_pers))
      picker_list.splice(key, 1)
      this.create_info.other_pick_pers = picker_list
    },
    addOtherPicker() {
      let picker = JSON.parse(JSON.stringify(this.other_pick_pers))
      if(picker.length == 0)return layer.msg('请选择采集人')
      this.create_info.other_pick_per.push(picker.pop())
      this.create_info.other_pick_pers.push(this.other_pick_pers)
      this.other_pick_pers = [];
    },
    modalClose() {
      this.modal_visible = false
    },
    // 定义 打开对话框时的 函数
    showModal() {
      this.modal_visible = true
    },

    pushOrder(order) {
      this.create_info.in_number = order
      this.modalClose();
    },


    numberInputOpen() {
      let count = 0
      this.samples.map((item) => {
        if (item.in_number == '') count++;
      })
      if (count > 0) layer.msg('请填写编号')
      this.num_input_show = true
    },
    getNumberGroup(num) {
      if (this.create_info.in_number === this.get_number) {
        this.$sa0.post({
          url: this.$api('批量生成选择编号'),
          data: {
            id: this.number_config.id,
            num: num
          }
        }).then((response) => {
          this.$sa0.response({
            response: response.data,
            then: (response) => {
              // 赋值
              let keys = []
              this.samples.map((item, key) => {
                if (item.in_number == '') {
                  keys.push(key)
                }
              })
              response.data.serialnumber.map((item, key) => {
                this.$set(this.samples[keys[key]], 'in_number', item)
              })
              this.numberInputOpen()
            },
            error: (response) => {
              layer.msg(response.message)
            },
          })
        })
      } else {
        this.numberInputOpen()
      }
    },
    addPush() {
      let data = {
        sample_arr: this.samples.map((item) => {
          let d = JSON.parse(JSON.stringify(item))
          let spec_types = JSON.parse(JSON.stringify(d.spec_types))
          let pick_pers = JSON.parse(JSON.stringify(d.pick_pers))
          let other_pick_pers = JSON.parse(JSON.stringify(d.other_pick_pers))
          d.other_pick_pers = JSON.stringify(d.other_pick_pers)
          d.other_pick_per = JSON.stringify(other_pick_pers.map((i) => {
            return i.pop()
          }))
          d.spec_types = JSON.stringify(d.spec_types)
          d.spec_type = spec_types.pop()
          d.pick_pers = JSON.stringify(d.pick_pers)
          if (pick_pers.length != 0) {
            d.pick_per = pick_pers.pop()
          }
          return d
        }),
        task_id: this.$route.params.id,
      }
      this.$sa0.post({
        url: this.$api('添加样品'), data
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.create_info = {
              spec_type: '',
              spec_types: [],
              name: '',
              in_number: '',
              pick_per: '',
              pick_pers: [],
              other_pick_per: [],
              other_pick_pers: [],
              jing_dot: '',
              wei_dot: '',
              pick_space: '',
            }
            this.listReload()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    checkSampleNumber() {
      let non = 0
      this.samples.map((item) => {
        if (item.in_number == '') non++
      })
      if (non === 0) {
        this.addPush()
      } else {
        this.getNumberGroup(non)
      }
    },
    saveSample() {
      // 检查基础填写
      if (this.create_info.spec_types.length === 0) return layer.msg('请选择样品类型')
      if (!this.create_info.name || this.create_info.name === '') return layer.msg('请输入样品名称')
      if (!this.create_info.in_number || this.create_info.in_number === '') return layer.msg('请输入内部编号')
      // 生成样品表单
      let item = this.create_info;
      this.samples = [item];
      let num = this.number_config.num
      for (let i = 2; i <= num; i++) {
        this.samples.push({
          spec_type: item.spec_type,
          spec_types: item.spec_types,
          name: item.name,
          in_number: '',
          pick_per: item.pick_per,
          pick_pers: item.pick_pers,
          other_pick_per: item.other_pick_per,
          other_pick_pers: item.other_pick_pers,
          jing_dot: item.jing_dot,
          wei_dot: item.wei_dot,
          pick_space: item.pick_space,
        })
      }
      this.checkSampleNumber()
    },
    pushNumber(id) {
      this.number_config.id = id
      this.$sa0.post({
        url: this.$api('批量生成选择编号'),
        data: {
          id: this.number_config.id,
          num: 1
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            // 赋值
            this.create_info.in_number = response.data.serialnumber[0]
            this.get_number = response.data.serialnumber[0]
            this.modalClose()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getDefaultNumber() {
      // 获取样品的默认ID
      this.$sa0.post({
        url: this.$api('获取样品的默认ID')
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            // 判断是不是存在 默认规则
            if (response.data.info) {
              // 执行 编号填写方法
              this.pushNumber(response.data.info.id)
            } else {
              layer.msg('样品不存在默认编号')
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getPickperList() {
      // 采集员列表
      this.$sa0.post({
        url: this.$api('采集员列表')
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            // 获取列表 赋值
            this.pickper_list = response.data.list
            // 打开弹框
            this.visible = true
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getSampleTypeList() {
      // 样品类型列表
      this.$sa0.post({
        url: this.$api('样品类型列表')
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.getPickperList();
            // 获取列表 赋值
            this.types_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    handleCancel() {
      this.visible = false
    },
    closeNumInput() {
      this.num_input_show = false
    },
  }
}
</script>
<template>
  <div>
    <a-modal title="编号确认" :visible="num_input_show" @ok="checkSampleNumber" @cancel="closeNumInput()">
      <div>
        <div v-for="(item,key) in samples" class="my-1">
          <a-space>
            <div><b>[{{ key + 1 }}] {{ item.name }}</b></div>
            <div>
              <a-input v-model="item.in_number" type="text"></a-input>
            </div>
          </a-space>
        </div>
      </div>
    </a-modal>
    <a-drawer height="700px" title="添加样品" placement="bottom" :visible="visible" @close="handleCancel">
      <div>
        <table class="layui-table">
          <colgroup>
            <col width="100">
            <col width="100">
            <col width="100">
            <col width="100">
            <col width="100">
            <col width="300">
            <col width="200">
          </colgroup>
          <tbody>
          <tr>
            <td class="text-red-400 table_title_bg">样品类型</td>
            <td colspan="3">
              <a-cascader class="w-full" v-model="create_info.spec_types"
                          :fieldNames="{ label: 'name', value: 'id', children: 'children' }" :options="types_list"
                          placeholder="请选择样品类型"/>
            </td>
            <td class="text-red-400 table_title_bg">样品名称</td>
            <td>
              <input v-model="create_info.name" type="text" class="input_wrapper">
            </td>
            <td class="text-red-400 table_title_bg">内部编号</td>
            <td colspan="3">
              <input v-model="create_info.in_number" type="text">
              <a-tooltip slot="suffix" title="使用默认规则" @click="getDefaultNumber()">
                <a-icon type="unordered-list" style="color: rgba(0,0,0,.45)"/>
              </a-tooltip>
              <a-button type="primary" @click="showModal()" style="margin-left: 10px">选择编号规则
              </a-button>
            </td>
          </tr>
          <tr>
            <td class="table_title_bg">经度</td>
            <td>
              <input v-model="create_info.jing_dot" type="text" class="input_wrapper">
            </td>
            <td class="table_title_bg">纬度</td>
            <td>
              <input v-model="create_info.wei_dot" type="text" class="input_wrapper">
            </td>
            <td class="table_title_bg">采集地点</td>
            <td>
              <input v-model="create_info.pick_space" type="text" class="input_wrapper">
            </td>
            <td class="table_title_bg">采集人</td>
            <td>
              <div>
                <a-cascader class="w-full" v-model="create_info.pick_pers"
                            :fieldNames="{ label: 'name', value: 'id', children: 'children' }" :options="pickper_list"
                            placeholder="请选择采集人"/>
                <div v-for="(item,key) in create_info.other_pick_pers">
                  <a-space>
                    <a-cascader style="width: 700px;" v-model="create_info.other_pick_pers[key]"
                                :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
                                :options="pickper_list"
                                placeholder="请选择采集人"/>
                    <a-button type="danger" @click="delOtherPicker(key)">删除</a-button>
                  </a-space>

                </div>
              </div>
              <div v-if="create_info.pick_pers.length > 0">
                <a-space>
                  <a-cascader style="width: 700px;" v-model="other_pick_pers"
                              :fieldNames="{ label: 'name', value: 'id', children: 'children' }" :options="pickper_list"
                              placeholder="请选择采集人"/>
                  <a-button type="primary" @click="addOtherPicker()">添加</a-button>
                </a-space>
              </div>
            </td>
          </tr>
          <tr>
            <td class="table_title_bg">添加数量</td>
            <td>
              <input v-model="number_config.num" type="text" class="input_wrapper">
            </td>
            <td colspan="8">
              数量大于1时将按照设置的样品编号规则自动生成后续后续编号,其余数据和之前的一致
            </td>
          </tr>
          </tbody>
          <div class="mt-2">
            <a-space>
              <a-button type="primary" @click="saveSample()">确定</a-button>
            </a-space>
          </div>
        </table>
      </div>
    </a-drawer>
    <Numberrulelistuser :pushNumber="pushNumber" :callback="pushOrder" :ok="modalClose" :cancel="modalClose"
                        :visible="modal_visible"></Numberrulelistuser>
  </div>
</template>
<style scoped>
td {
  line-height: 40px;
  padding: 0 10px;
}

.input_wrapper {
  width: 100%;
  height: 100%;
}

.table_title_bg {
  background: #FAFAFA;
}

.layui-table td {
  padding: 0 10px;
  height: 40px;
  border-color: #eeeeee;
}

.image_image_wrapper img {
  width: 100%;
  height: 100%;
}
</style>
