<script>
/***
 Name: add_test_item
 Code: sa0ChunLuyu
 Time: 2021/11/25 13:53
 Remark: 添加检测项目
 */
export default {
  props: ['checkReload'],
  data() {
    return {
      test_info: {
        check_items: [],
        check_funcs: [],
        check_pers: [],
        read_pers: [],
      },
      func_list: [],
      item_list: [],
      check_list: [],
      read_list: [],
      item_info: false,
      visible: false
    }
  },
  mounted() {
  },
  methods: {
    onClose() {
      this.visible = false
    },
    getCheckPerList(info, clear = true) {
      let check_funcs = JSON.parse(JSON.stringify(this.test_info.check_funcs))
      this.$sa0.post({
        url: this.$api('能力ID获取检测人员列表'),
        data: {
          bind_id: check_funcs.pop()
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.check_list = response.data.list
            if (clear) {
              this.test_info.check_pers = [];
            }
          },
          error: (response) => {
            this.check_list = []
            layer.msg(response.message)
          },
        })
      })
    },
    getBindListByTypeItemFunc(clear = true) {
      this.getCheckPerList('', clear)
    },
    getFuncListByItemType(clear = true) {
      let check_items = JSON.parse(JSON.stringify(this.test_info.check_items))
      this.$sa0.post({
        url: this.$api('根据样品ID项目获取检测方法列表'),
        data: {
          type_id: this.test_info.spec_type,
          item_id: check_items.pop(),
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.func_list = response.data.list
            if (this.clear) {
              this.test_info.check_funcs = [];
              this.test_info.check_pers = [];
            } else {
              this.getBindListByTypeItemFunc(false)
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getReaderList() {
      this.$sa0.post({
        url: this.$api('核对人员列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.read_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getItemListByType(info) {
      this.$sa0.post({
        url: this.$api('根据样品ID获取项目列表'),
        data: {
          type_id: info.spec_type
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.getReaderList()
            this.item_list = response.data.list
            this.test_info = JSON.parse(JSON.stringify(info))
            this.getFuncListByItemType(false)
            this.visible = true
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    checkFuncsChange() {
      this.getBindListByTypeItemFunc()
    },
    checkItemsChange() {
      this.getFuncListByItemType()
    },
    addTaskCheck() {
      if (this.test_info.check_items.length === 0) return layer.msg('请选择检测项目')
      if (this.test_info.check_funcs.length === 0) return layer.msg('请选择检测方法')
      if (this.test_info.check_pers.length === 0) return layer.msg('请选择检测人员')
      if (this.test_info.read_pers.length === 0) return layer.msg('请选择核对人员')
      let info = JSON.parse(JSON.stringify(this.test_info))
      let check_items = JSON.parse(JSON.stringify(info.check_items))
      let check_funcs = JSON.parse(JSON.stringify(info.check_funcs))
      let check_pers = JSON.parse(JSON.stringify(info.check_pers))
      let read_pers = JSON.parse(JSON.stringify(info.read_pers))
      let data = {
        id: this.test_info.id,
        check_info: {
          check_items: JSON.stringify(info.check_items),
          check_funcs: JSON.stringify(info.check_funcs),
          check_pers: JSON.stringify(info.check_pers),
          read_pers: JSON.stringify(info.read_pers),
          check_item: check_items.pop(),
          check_func: check_funcs.pop(),
          check_per: check_pers.pop(),
          read_per: read_pers.pop(),
        }
      }
      this.$sa0.post({
        url: this.$api('修改任务检测'),
        data
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.checkReload()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
  }
}
</script>
<template>
  <div>
    <a-drawer
        title="添加检测"
        placement="bottom"
        :visible="visible"
        height="290px"
        @close="onClose"
    >
      <div>
        <table class="layui-table">
          <colgroup>
            <col width="100">
            <col width="100">
            <col width="100">
            <col width="100">
            <col width="100">
            <col width="300">
            <col width="200">
          </colgroup>
          <tbody>
          <tr>
            <td class="table_title_bg">样品名称</td>
            <td>{{ test_info.spec_name }}</td>
            <td class="table_title_bg">内部编号</td>
            <td>{{ test_info.spec_number }}</td>
          </tr>
          <tr>
            <td class="text-red-400 table_title_bg">检测项目</td>
            <td>
              <a-cascader
                  class="w-full" v-model="test_info.check_items"
                  :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
                  :options="item_list" placeholder="请选择检测项目" @change="checkItemsChange"/>
            </td>
            <td class="text-red-400 table_title_bg">检测方法</td>
            <td>
              <a-cascader
                  v-if="test_info.check_items.length > 0"
                  class="w-full" v-model="test_info.check_funcs"
                  :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
                  :options="func_list" placeholder="请选择检测方法" @change="checkFuncsChange"/>
              <span v-else>请先选择检测项目</span>
            </td>
          </tr>
          <tr>
            <td class="text-red-400 table_title_bg">检测人员</td>
            <td>
              <a-cascader
                  v-if="test_info.check_funcs.length > 0"
                  class="w-full" v-model="test_info.check_pers"
                  :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
                  :options="check_list" placeholder="请选择检测人员"/>
              <span v-else>请先选择检测方法</span>
            </td>
            <td class="text-red-400 table_title_bg">核对人员</td>
            <td>

              <a-cascader
                  class="w-full" v-model="test_info.read_pers"
                  :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
                  :options="read_list" placeholder="请选择核对人员"/>
            </td>
          </tr>
          </tbody>
          <div class="mt-2">
            <a-space>
              <a-button @click="addTaskCheck()" type="primary">确定</a-button>
            </a-space>
          </div>
        </table>
      </div>
    </a-drawer>
  </div>
</template>
<style scoped>
td {
  line-height: 40px;
  padding: 0 10px;
}

.input_wrapper {
  width: 100%;
  height: 100%;
}

.table_title_bg {
  background: #FAFAFA;
}

.layui-table td {
  padding: 0 10px;
  height: 40px;
  border-color: #eeeeee;
}

.image_image_wrapper img {
  width: 100%;
  height: 100%;
}
</style>
